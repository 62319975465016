import React, { useState } from "react";
import MailCheckModule from "./MailCheckModule";
import LoginModule from "./LoginModule";
import RegModule from "./RegModule";
import PassRecoveryModule from "./PassRecoveryModule";
import SuccessRecoveryModule from "./SuccessRecoveryModule";

type AuthStep =
  | "reg"
  | "login"
  | "mailcheck"
  | "passrecovery"
  | "successrecovery";
type Credentials = { email: string; password: string; login: string };

export type ModuleProps = {
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
  credentials: Credentials;
  setCredentials: React.Dispatch<React.SetStateAction<Credentials>>;
};

const Modules = (props: ModuleProps) => ({
  reg: <RegModule {...props} />,
  login: <LoginModule {...props} />,
  mailcheck: <MailCheckModule {...props} />,
  passrecovery: <PassRecoveryModule {...props} />,
  successrecovery: <SuccessRecoveryModule {...props} />,
});

const WindowAuth: React.FC = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    login: "",
  });
  const [step, setStep] = useState<AuthStep>("mailcheck");

  return Modules({ setStep, credentials, setCredentials })[step];
};
export default WindowAuth;
