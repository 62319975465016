import GeoJSON from "ol/format/GeoJSON";
import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { RFeature, RLayerVector, RPopup,  } from "rlayers";
import { Layer } from "../../config/interfaces";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { select, deselect, setSelectedFeature } from "../../../../redux/map/featureReducer";
import { set_center } from "../../../../redux/map/mapReducers";
import { CustomInfoWindow } from "../common/CustomInfowindowCreator";
import { Style, Fill, Stroke, RegularShape } from 'ol/style';
import { FeatureLike } from 'ol/Feature';

interface MapPointsCreatorInterface {
  layer: Layer;
}

export function NewMapPointsCreator(layer_prop: MapPointsCreatorInterface) {
  const layer = layer_prop.layer;

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, type: string, color_hex: string } }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  const [data, setData] = React.useState({ features: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const flyTo = (e: any) => {
    const [xMin, yMin, xMax, yMax] = e.target.getGeometry().getExtent();
    const newExt = [xMin + xMin * 0.002, yMin, xMax + xMax * 0.002, yMax];
    e.map.getView().fit(newExt, {
      duration: 750,
      maxZoom: 9,
    })
  }
  
  const dispatch = useDispatch()

  const handleSetCurrent = (e: any) => {
    setCurrent(e);

    console.log('e.getGeometry()', e.getGeometry().getExtent());
    console.log(e.getProperties());

    dispatch(select());
    dispatch(setSelectedFeature(e.getProperties()));
    dispatch(set_center([41.8343359, 47.3376578]));
  }

  const handleDeselectCurrent = (e: any) => {
    setCurrent(e);
    dispatch(deselect());
  }

  let fillColor = '#000000';
  const outlineColor = '#ffffff';

  return (
    <>
    <RLayerVector
    zIndex={1}
    format={parser}
    url={layer_url}
    style={useCallback(
        (feature: FeatureLike, resolution: number) => {
        return new Style({
            image: new RegularShape({
            fill: new Fill({
                color: feature.getProperties()['color_hex'],
            }),
            stroke: new Stroke({
                color: outlineColor, 
                width: 1, 
            }),
            points: 4,
            radius: 12, 
            angle: Math.PI / 4, // Поворот на 45 градусов (в радианах)
            }),
        });
        },
        [fillColor, outlineColor] 
    )}
    onPointerEnter={useCallback((e: any) => handleSetCurrent(e.target), [])}
    onPointerLeave={useCallback(
        (e: any) => current === e.target && handleDeselectCurrent(null),
        [current]
    )}
    onClick={flyTo}>
        </RLayerVector>
        

        { layer.label ? 
        <RLayerVector zIndex={3}>
            {current ? (
            <div>
                <RFeature geometry={current.getGeometry()}>
                <RPopup trigger={"hover"} 
                    positioning='top-left'
                >
                {layer.marker && layer.infowindow ?
                    <CustomInfoWindow 
                    marker={layer.marker} 
                    infowindow={layer.infowindow}
                    properties={current.getProperties()}/>
                    : null
                }
                </RPopup>
                </RFeature>
            </div>
            ) : null}
        </RLayerVector> : null }
        
    </>
    );


    }