import React, { useState, useEffect } from "react";
import styles from "./Auth.module.scss";
import { ModuleProps } from "./WindowAuth";
import Button from "../Button/Button";
import { TextField } from "../Input/TextField/TextField";
import { loginWithCredentials } from "../../auth/AuthApiService";
import { useNavigate } from "react-router-dom";
import useEnterKeyListener from "../../hooks/useEnterKeyListener";

export default function LoginModule({
  setStep,
  credentials,
  setCredentials,
}: ModuleProps) {
  const [error, setError] = useState("");
  const [touched, setTouched] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!credentials.password) {
      !touched && setTouched(true);
      setError("Введите пароль");
    } else {
      const loginResult = await loginWithCredentials(
        credentials.email,
        credentials.password
      );
      if (!!loginResult) {
        setError("");
        navigate("/map");
      } else {
        setError("Неверный пароль");
      }
    }
  };

  useEffect(() => {
    if (!credentials.password && touched) {
      setError("Введите пароль");
    } else {
      setError("");
    }
  }, [credentials.password, touched]);

  const handleKeyDown = () => {
    setIsEnterPressed(true);
  };

  const handleKeyUp = () => {
    setIsEnterPressed(false);
    handleLogin();
  };

  useEnterKeyListener(handleKeyDown, handleKeyUp);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Вход</div>
          <button
            className={styles.back}
            onClick={() => {
              setStep("mailcheck");
            }}
          >
            Назад
          </button>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Электронная почта</div>
          <TextField type="text" value={credentials.email} disabled />
          <div className={styles.fieldTitle}>Пароль </div>
          <TextField
            type="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCredentials((prev) => ({ ...prev, password: e.target.value }));
            }}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={!error}
            validationMessage={error}
          />
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={handleLogin}
          style={{
            width: "100%",
            backgroundColor: isEnterPressed ? "#0a1f70" : "",
          }}
        />
        <div className={styles.flexCenterContainer}>
          <div
            className={styles.footerLink}
            // onClick={() => setStep("passrecovery")}
          >
            Забыли пароль?
          </div>
        </div>
      </div>
    </div>
  );
}
