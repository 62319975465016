import { Layer } from "../../config/interfaces";
import { MapPolygonCreator, MapLineCreator, 
  MapCartogramCreator, MapPieCreator, NewMapPointsCreator } from "./index";
import { useOL } from "rlayers";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function MapCreator(layers: AtlasLayerInterface) {
  const all_layers = layers.layers;

  const get_all_layers_index = (layer_name: string) => {
    let ind = 0;
    let geom_type = null;
    all_layers.map((layer, index) => {
      if (layer.layer === layer_name) {
        ind = index;
        geom_type = layer.geometry_type;
      }
    })
    return ind;
  }

  const { map } = useOL();
  const getOrder = (ol_layer: any, index: number) => {
    if (ol_layer.get('url')) {
      let layer_name = ol_layer.get('url').split("%")[1].split("&")[0].replace('3A', '');
      
      let order = get_all_layers_index(layer_name);
      if (index != order + 1) {
        map.getAllLayers()[index].setZIndex(order+1);
      }
    }
    
  }
  map.getAllLayers().map((ol_layer, index) => getOrder(ol_layer, index));

  const getLayerComponent = (layer: Layer) => {
    if (layer.geometry_type === "points") {
      return <NewMapPointsCreator layer={layer}/>
    }
    if (layer.geometry_type === "polygon") {
      return <MapPolygonCreator layer={layer}/>
    }
    if (layer.geometry_type === "line") {
      return <MapLineCreator layer={layer}/>
    }
    if (layer.geometry_type === "cartogram") {
      return <MapCartogramCreator layer={layer}/>
    }
    if (layer.geometry_type === "pie") {
      return <MapPieCreator layer={layer}/>
    }
    else {
      return null;
    }
  }

  return (
    <>
        {all_layers.map((layer) => (
          layer.visible ? getLayerComponent(layer) : null
        ))}
    </>
  );
}
